<template>
    <section class="section">
        <div class="container mx-0 container-dash">
            <div class="row gutters-64">
                <div class="col-lg-6">
                    <div class="mobail-block-dark mb-[23px]">
                        <h1 class="heading heading-1 font-bold">
                            <span class="block text-primary-500">TinkWs Reviews</span>
                            What they say about us 🏆
                        </h1>
                    </div>
                    <div class="fixed">
                    <div class="bg-secondary-100 reveiws-block mb-[26px] rounded-[8px]">
                        <div class="reveiws-block__top ">
                            <div class="reveiws-block__total mb-[17px]">
                                <div class="reveiws-block__total-body">
                                    <span class="reveiws-block__total-current">4.9</span>
                                    <span>of 5</span>
                                </div>
                            </div>
                            <div class="reveiws-block__title font-semibold mb-[17px]">
                                TinkWs has been rated excellent by 2321 clients!
                            </div>
                        </div>
                        <div class="reveiws-block__stars mb-[20px]">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                        </div>
                        <div class="reveiws-block__progress">
                            <div class="reveiws-block__wrap">
                                <span class="font-medium">5 stars</span>
                                <div class="reveiws-block__item">
                                    <span style="width: 84%;"></span>
                                </div>
                                <span>84%</span>
                            </div>
                            <div class="reveiws-block__wrap">
                                <span class="font-medium">4 stars</span>
                                <div class="reveiws-block__item">
                                    <span style="width: 9%;"></span>
                                </div>
                                <span>9%</span>
                            </div>
                            <div class="reveiws-block__wrap">
                                <span class="font-medium">3 stars</span>
                                <div class="reveiws-block__item">
                                    <span style="width: 4%;"></span>
                                </div>
                                <span>4%</span>
                            </div>
                            <div class="reveiws-block__wrap">
                                <span class="font-medium">2 stars</span>
                                <div class="reveiws-block__item">
                                    <span style="width: 2%;"></span>
                                </div>
                                <span>2%</span>
                            </div>
                            <div class="reveiws-block__wrap">
                                <span class="font-medium">1 stars</span>
                                <div class="reveiws-block__item">
                                    <span style="width: 1%;"></span>
                                </div>
                                <span>1%</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <a href="#" class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between max-w-[336px]"
                         @click.prevent="openModal('write-review')">
                            <span class="info text-[14px] font-bold">
                                Rate TinkWs
                            </span>
                            <span class="ico ico-24 ml-[10px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.31818 11.6361L21 11.6361" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.3636 19.2727L21 11.6364L13.3636 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>
                        </a>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 mt-[30px] lg:mt-0">
                    <!-- <div class="user-review-block bg-secondary-100 rounded-[8px]">
                        <div class="user-block mb-[15px]">
                            <div class="user-block-icon">
                                AM
                            </div>
                            <div class="user-block-content">
                                <h4 class="user-block-name">Athar Malakooti</h4>
                                <p class="user-block-text"><img src="@/assets/img/verified-account.svg" class="mr-[10px]" alt=""> Verified customer</p>
                            </div>
                        </div>
                        <div class="reveiws-block__stars justify-start mb-[8px]">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                        </div>
                        <div class="font-semibold mb-[27px]">
                            “Best fast reliable service time and time again.”
                        </div>
                        <p class="text-text2 text-[13px] font-medium"><img src="@/assets/img/tik-tok-iocn.svg" alt=""> 500 Real likes</p>
                    </div>
                    <div class="user-review-block bg-secondary-100 rounded-[8px]">
                        <div class="user-block mb-[15px]">
                            <div class="user-block-icon">
                                AM
                            </div>
                            <div class="user-block-content">
                                <h4 class="user-block-name">Athar Malakooti</h4>
                                <p class="user-block-text"><img src="@/assets/img/verified-account.svg" class="mr-[10px]" alt=""> Verified customer</p>
                            </div>
                        </div>
                        <div class="reveiws-block__stars justify-start mb-[8px]">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                        </div>
                        <div class="font-semibold mb-[27px]">
                            Great app feature for marketing!! 🔥”
                        </div>
                        <p class="text-text2 text-[13px] font-medium"><img src="@/assets/img/tik-tok-iocn.svg" alt=""> 500 Real likes</p>
                    </div>
                    <div class="user-review-block bg-secondary-100 rounded-[8px]">
                        <div class="user-block mb-[15px]">
                            <div class="user-block-icon">
                                <img src="@/assets/img/user-iocn-21.png" alt="">
                            </div>
                            <div class="user-block-content">
                                <h4 class="user-block-name">Athar Malakooti</h4>
                                <p class="user-block-text"><img src="@/assets/img/verified-account.svg" class="mr-[10px]" alt=""> Verified customer</p>
                            </div>
                        </div>
                        <div class="reveiws-block__stars justify-start mb-[8px]">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                            <img src="@/assets/img/magic-star.svg" alt="">
                        </div>
                        <div class="font-semibold mb-[27px]">
                            Great app feature for marketing!! 🔥”
                        </div>
                        <p class="text-text2 text-[13px] font-medium"><img src="@/assets/img/tik-tok-iocn.svg" alt=""> 500 Real likes</p>
                    </div> -->
                    <div class="accordeons flex flex-col">
                        <div class="accordeons-container flex flex-col">
                            <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                
                                <div class="review-2--header">
                                    <div class="profile-card mb-[15px]">
                                        <div class="user-icon rounded-full">AM</div>
                                        <div class="profile-content flex flex-col ml-[25px]">
                                            <div class="profile-content--item flex-col mb-[-5px]">
                                                <h3 class="profile-title mr-[30px] pb-[5px]">Athar Malakooti</h3>
                                                <div class="status flex items-center pb-[5px]">
                                                    <span class="ico ico-20"><img src="@/assets/img/verified-account.svg"></span>
                                                    <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                        Verified customer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-content--item">
                                        <div class="reveiws-block__stars justify-start mb-[8px]">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2--body">
                                    <p class="def-text--1 font-semibold">
                                        <q>
                                            Best fast reliable service
                                            time and time again.
                                        </q>
                                    </p>
                                </div>
                                <div class="review-2--footer">
                                    <div class="flex items-center def-text--4 text-text2">
                                        <span class="ico ico-20"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6588 7.11918C13.5761 7.12682 13.4927 7.13191 13.4087 7.13191C12.4574 7.13191 11.6212 6.64318 11.1344 5.90309C11.1344 7.84336 11.1344 10.0515 11.1344 10.0885C11.1344 11.7971 9.749 13.1818 8.041 13.1818C6.333 13.1812 4.94763 11.7958 4.94763 10.0878C4.94763 8.37918 6.333 6.99445 8.041 6.99445C8.1059 6.99445 8.1689 7.00018 8.2319 7.004V8.52873C8.16827 8.52109 8.10654 8.50964 8.041 8.50964C7.16918 8.50964 6.46218 9.21664 6.46218 10.0885C6.46218 10.9603 7.16854 11.6673 8.041 11.6673C8.91345 11.6673 9.68345 10.98 9.68345 10.1082C9.68345 10.0732 9.69872 3 9.69872 3H11.1554C11.2922 4.30264 12.3441 5.331 13.6588 5.42518V7.11918Z" fill="currentColor"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7476 0H4.14763C2.38032 0 0.947632 1.43269 0.947632 3.2V12.8C0.947632 14.5673 2.38032 16 4.14763 16H13.7476C15.5149 16 16.9476 14.5673 16.9476 12.8V3.2C16.9476 1.43269 15.5149 0 13.7476 0ZM2.54763 3.2C2.54763 2.31634 3.26398 1.6 4.14763 1.6H13.7476C14.6313 1.6 15.3476 2.31634 15.3476 3.2V12.8C15.3476 13.6837 14.6313 14.4 13.7476 14.4H4.14763C3.26398 14.4 2.54763 13.6837 2.54763 12.8V3.2Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span class="info font-medium ml-[5px]">
                                            500 Real likes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                <div class="review-2--header">
                                    <div class="profile-card mb-[15px]">
                                        <div class="user-icon rounded-full">AM</div>
                                        <div class="profile-content flex flex-col ml-[25px]">
                                            <div class="profile-content--item flex-col flex-col mb-[-5px]">
                                                <h3 class="profile-title mr-[30px] pb-[5px]">Athar Malakooti</h3>
                                                <div class="status flex items-center pb-[5px]">
                                                    <span class="ico ico-20"><img src="@/assets/img/verified-account.svg"></span>
                                                    <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                        Verified customer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-content--item">
                                        <div class="reveiws-block__stars justify-start mb-[8px]">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2--body">
                                    <p class="def-text--1 font-semibold">
                                        <q>
                                            Best fast reliable service
                                            time and time again.
                                        </q>
                                    </p>
                                </div>
                                <div class="review-2--footer">
                                    <div class="flex items-center def-text--4 text-text2">
                                        <span class="ico ico-20"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6588 7.11918C13.5761 7.12682 13.4927 7.13191 13.4087 7.13191C12.4574 7.13191 11.6212 6.64318 11.1344 5.90309C11.1344 7.84336 11.1344 10.0515 11.1344 10.0885C11.1344 11.7971 9.749 13.1818 8.041 13.1818C6.333 13.1812 4.94763 11.7958 4.94763 10.0878C4.94763 8.37918 6.333 6.99445 8.041 6.99445C8.1059 6.99445 8.1689 7.00018 8.2319 7.004V8.52873C8.16827 8.52109 8.10654 8.50964 8.041 8.50964C7.16918 8.50964 6.46218 9.21664 6.46218 10.0885C6.46218 10.9603 7.16854 11.6673 8.041 11.6673C8.91345 11.6673 9.68345 10.98 9.68345 10.1082C9.68345 10.0732 9.69872 3 9.69872 3H11.1554C11.2922 4.30264 12.3441 5.331 13.6588 5.42518V7.11918Z" fill="currentColor"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7476 0H4.14763C2.38032 0 0.947632 1.43269 0.947632 3.2V12.8C0.947632 14.5673 2.38032 16 4.14763 16H13.7476C15.5149 16 16.9476 14.5673 16.9476 12.8V3.2C16.9476 1.43269 15.5149 0 13.7476 0ZM2.54763 3.2C2.54763 2.31634 3.26398 1.6 4.14763 1.6H13.7476C14.6313 1.6 15.3476 2.31634 15.3476 3.2V12.8C15.3476 13.6837 14.6313 14.4 13.7476 14.4H4.14763C3.26398 14.4 2.54763 13.6837 2.54763 12.8V3.2Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span class="info font-medium ml-[5px]">
                                            500 Real likes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                <div class="review-2--header">
                                    <div class="profile-card mb-[15px]">
                                        <div class="user-icon rounded-full"><img src="@/assets/img/user-logo.png"></div>
                                        <div class="profile-content flex flex-col ml-[25px]">
                                            <div class="profile-content--item flex-col mb-[-5px]">
                                                <h3 class="profile-title mr-[30px] pb-[5px]">@wimwillems</h3>
                                                <div class="status flex items-center pb-[5px]">
                                                    <span class="ico ico-20"><img src="@/assets/img/verified-account.svg"></span>
                                                    <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                        Verified customer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-content--item">
                                        <div class="reveiws-block__stars justify-start mb-[8px]">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2--body">
                                    <p class="def-text--1 font-semibold">
                                        <q>
                                            Best fast reliable service time and time again.
                                            Great app feature for marketing!! 🔥
                                        </q>
                                    </p>
                                </div>
                                <div class="review-2--footer">
                                    <div class="flex items-center def-text--4 text-text2">
                                        <span class="ico ico-20"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6588 7.11918C13.5761 7.12682 13.4927 7.13191 13.4087 7.13191C12.4574 7.13191 11.6212 6.64318 11.1344 5.90309C11.1344 7.84336 11.1344 10.0515 11.1344 10.0885C11.1344 11.7971 9.749 13.1818 8.041 13.1818C6.333 13.1812 4.94763 11.7958 4.94763 10.0878C4.94763 8.37918 6.333 6.99445 8.041 6.99445C8.1059 6.99445 8.1689 7.00018 8.2319 7.004V8.52873C8.16827 8.52109 8.10654 8.50964 8.041 8.50964C7.16918 8.50964 6.46218 9.21664 6.46218 10.0885C6.46218 10.9603 7.16854 11.6673 8.041 11.6673C8.91345 11.6673 9.68345 10.98 9.68345 10.1082C9.68345 10.0732 9.69872 3 9.69872 3H11.1554C11.2922 4.30264 12.3441 5.331 13.6588 5.42518V7.11918Z" fill="currentColor"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7476 0H4.14763C2.38032 0 0.947632 1.43269 0.947632 3.2V12.8C0.947632 14.5673 2.38032 16 4.14763 16H13.7476C15.5149 16 16.9476 14.5673 16.9476 12.8V3.2C16.9476 1.43269 15.5149 0 13.7476 0ZM2.54763 3.2C2.54763 2.31634 3.26398 1.6 4.14763 1.6H13.7476C14.6313 1.6 15.3476 2.31634 15.3476 3.2V12.8C15.3476 13.6837 14.6313 14.4 13.7476 14.4H4.14763C3.26398 14.4 2.54763 13.6837 2.54763 12.8V3.2Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span class="info font-medium ml-[5px]">
                                            500 Real likes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                <div class="review-2--header">
                                    <div class="profile-card mb-[15px]">
                                        <div class="user-icon rounded-full"><img src="@/assets/img/user-logo.png"></div>
                                        <div class="profile-content flex flex-col ml-[25px]">
                                            <div class="profile-content--item flex-col mb-[-5px]">
                                                <h3 class="profile-title mr-[30px] pb-[5px]">@wimwillems</h3>
                                                <div class="status flex items-center pb-[5px]">
                                                    <span class="ico ico-20"><img src="@/assets/img/verified-account.svg"></span>
                                                    <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                        Verified customer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-content--item">
                                        <div class="reveiws-block__stars justify-start mb-[8px]">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2--body">
                                    <p class="def-text--1 font-semibold">
                                        <q>
                                            Best fast reliable service time and time again.
                                            Great app feature for marketing!! 🔥
                                        </q>
                                    </p>
                                </div>
                                <div class="review-2--footer">
                                    <div class="flex items-center def-text--4 text-text2">
                                        <span class="ico ico-20"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6588 7.11918C13.5761 7.12682 13.4927 7.13191 13.4087 7.13191C12.4574 7.13191 11.6212 6.64318 11.1344 5.90309C11.1344 7.84336 11.1344 10.0515 11.1344 10.0885C11.1344 11.7971 9.749 13.1818 8.041 13.1818C6.333 13.1812 4.94763 11.7958 4.94763 10.0878C4.94763 8.37918 6.333 6.99445 8.041 6.99445C8.1059 6.99445 8.1689 7.00018 8.2319 7.004V8.52873C8.16827 8.52109 8.10654 8.50964 8.041 8.50964C7.16918 8.50964 6.46218 9.21664 6.46218 10.0885C6.46218 10.9603 7.16854 11.6673 8.041 11.6673C8.91345 11.6673 9.68345 10.98 9.68345 10.1082C9.68345 10.0732 9.69872 3 9.69872 3H11.1554C11.2922 4.30264 12.3441 5.331 13.6588 5.42518V7.11918Z" fill="currentColor"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7476 0H4.14763C2.38032 0 0.947632 1.43269 0.947632 3.2V12.8C0.947632 14.5673 2.38032 16 4.14763 16H13.7476C15.5149 16 16.9476 14.5673 16.9476 12.8V3.2C16.9476 1.43269 15.5149 0 13.7476 0ZM2.54763 3.2C2.54763 2.31634 3.26398 1.6 4.14763 1.6H13.7476C14.6313 1.6 15.3476 2.31634 15.3476 3.2V12.8C15.3476 13.6837 14.6313 14.4 13.7476 14.4H4.14763C3.26398 14.4 2.54763 13.6837 2.54763 12.8V3.2Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span class="info font-medium ml-[5px]">
                                            500 Real likes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                <div class="review-2--header">
                                    <div class="profile-card mb-[15px]">
                                        <div class="user-icon rounded-full"><img src="@/assets/img/user-logo.png"></div>
                                        <div class="profile-content flex flex-col ml-[25px]">
                                            <div class="profile-content--item flex-col mb-[-5px]">
                                                <h3 class="profile-title mr-[30px] pb-[5px]">@wimwillems</h3>
                                                <div class="status flex items-center pb-[5px]">
                                                    <span class="ico ico-20"><img src="@/assets/img/verified-account.svg"></span>
                                                    <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                        Verified customer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-content--item">
                                        <div class="reveiws-block__stars justify-start mb-[8px]">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2--body">
                                    <p class="def-text--1 font-semibold">
                                        <q>
                                            Best fast reliable service time and time again.
                                            Great app feature for marketing!! 🔥
                                        </q>
                                    </p>
                                </div>
                                <div class="review-2--footer">
                                    <div class="flex items-center def-text--4 text-text2">
                                        <span class="ico ico-20"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6588 7.11918C13.5761 7.12682 13.4927 7.13191 13.4087 7.13191C12.4574 7.13191 11.6212 6.64318 11.1344 5.90309C11.1344 7.84336 11.1344 10.0515 11.1344 10.0885C11.1344 11.7971 9.749 13.1818 8.041 13.1818C6.333 13.1812 4.94763 11.7958 4.94763 10.0878C4.94763 8.37918 6.333 6.99445 8.041 6.99445C8.1059 6.99445 8.1689 7.00018 8.2319 7.004V8.52873C8.16827 8.52109 8.10654 8.50964 8.041 8.50964C7.16918 8.50964 6.46218 9.21664 6.46218 10.0885C6.46218 10.9603 7.16854 11.6673 8.041 11.6673C8.91345 11.6673 9.68345 10.98 9.68345 10.1082C9.68345 10.0732 9.69872 3 9.69872 3H11.1554C11.2922 4.30264 12.3441 5.331 13.6588 5.42518V7.11918Z" fill="currentColor"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7476 0H4.14763C2.38032 0 0.947632 1.43269 0.947632 3.2V12.8C0.947632 14.5673 2.38032 16 4.14763 16H13.7476C15.5149 16 16.9476 14.5673 16.9476 12.8V3.2C16.9476 1.43269 15.5149 0 13.7476 0ZM2.54763 3.2C2.54763 2.31634 3.26398 1.6 4.14763 1.6H13.7476C14.6313 1.6 15.3476 2.31634 15.3476 3.2V12.8C15.3476 13.6837 14.6313 14.4 13.7476 14.4H4.14763C3.26398 14.4 2.54763 13.6837 2.54763 12.8V3.2Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span class="info font-medium ml-[5px]">
                                            500 Real likes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="review-2 bg-primary-100 rounded-[8px] flex flex-col">
                                <div class="review-2--header">
                                    <div class="profile-card mb-[15px]">
                                        <div class="user-icon rounded-full"><img src="@/assets/img/user-logo.png"></div>
                                        <div class="profile-content flex flex-col ml-[25px]">
                                            <div class="profile-content--item flex-col mb-[-5px]">
                                                <h3 class="profile-title mr-[30px] pb-[5px]">@wimwillems</h3>
                                                <div class="status flex items-center pb-[5px]">
                                                    <span class="ico ico-20"><img src="@/assets/img/verified-account.svg"></span>
                                                    <span class="status-text def-text--3 font-semibold text-text2 ml-[10px]">
                                                        Verified customer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-content--item">
                                        <div class="reveiws-block__stars justify-start mb-[8px]">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                            <img src="@/assets/img/magic-star.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="review-2--body">
                                    <p class="def-text--1 font-semibold">
                                        <q>
                                            Best fast reliable service time and time again.
                                            Great app feature for marketing!! 🔥
                                        </q>
                                    </p>
                                </div>
                                <div class="review-2--footer">
                                    <div class="flex items-center def-text--4 text-text2">
                                        <span class="ico ico-20"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6588 7.11918C13.5761 7.12682 13.4927 7.13191 13.4087 7.13191C12.4574 7.13191 11.6212 6.64318 11.1344 5.90309C11.1344 7.84336 11.1344 10.0515 11.1344 10.0885C11.1344 11.7971 9.749 13.1818 8.041 13.1818C6.333 13.1812 4.94763 11.7958 4.94763 10.0878C4.94763 8.37918 6.333 6.99445 8.041 6.99445C8.1059 6.99445 8.1689 7.00018 8.2319 7.004V8.52873C8.16827 8.52109 8.10654 8.50964 8.041 8.50964C7.16918 8.50964 6.46218 9.21664 6.46218 10.0885C6.46218 10.9603 7.16854 11.6673 8.041 11.6673C8.91345 11.6673 9.68345 10.98 9.68345 10.1082C9.68345 10.0732 9.69872 3 9.69872 3H11.1554C11.2922 4.30264 12.3441 5.331 13.6588 5.42518V7.11918Z" fill="currentColor"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7476 0H4.14763C2.38032 0 0.947632 1.43269 0.947632 3.2V12.8C0.947632 14.5673 2.38032 16 4.14763 16H13.7476C15.5149 16 16.9476 14.5673 16.9476 12.8V3.2C16.9476 1.43269 15.5149 0 13.7476 0ZM2.54763 3.2C2.54763 2.31634 3.26398 1.6 4.14763 1.6H13.7476C14.6313 1.6 15.3476 2.31634 15.3476 3.2V12.8C15.3476 13.6837 14.6313 14.4 13.7476 14.4H4.14763C3.26398 14.4 2.54763 13.6837 2.54763 12.8V3.2Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span class="info font-medium ml-[5px]">
                                            500 Real likes
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordeon-footer max-w-max mx-auto flex flex-col align-center">
                            <a href="#" class="btn flex-col text-center def-text--1 text-primary-500">
                                <span class="font-semibold">
                                    See more
                                </span>
                                <span class="ico ico-20 mt-[4px]">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    methods: {
        openModal(name){
            this.$bus.$emit('modal',{
                name: name,
                overlay: true,
                payload: {},
            })
        },
    },
}
</script>