import restInstance from "@/lib/rest"
import axios from "axios";

const rest = restInstance

export const profileApi = {

    async user()  {
        return await rest.request('/profile/user', 'GET', null, 'user')
    },
    
    async emailChangeConfirm(name)  {
        const formData = new FormData();
        formData.append("email", name);
        return await rest.request('/profile/email-change/send-confirm', 'POST', formData, 'emailChangeConfirm', 10000)
    },
    
    async emailChangeUpdate(payload)  {
        return await rest.request('/profile/email-change/update', 'PUT', payload, 'emailChangeUpdate', 10000)
    },

    async requestDeletion()  {
        return await rest.request('/profile/request-deletion', 'PUT', null, 'requestDeletion')
    },

    async resendEmailConfirmation() {
        return await rest.request('/email/resend', 'POST', null, 'resendEmailConfirmation')
    },
    
    async emailConfirm(email)  {
        let body;
        if(!email) {
            body = null
        } else {
            body = new FormData();
            formData.append("email", email);
        }
        return await rest.request('/profile/send-confirm-code', 'POST', body, 'emailConfirm', 10000)
    },
    
    async update(payload)  {
        console.log('payload', payload)
        const data = {
            first_name: payload.firstName,
            last_name: payload.lastName,       
        }

        return await rest.request('/profile', 'PUT', data, 'update')
    },
    
    async value(payload)  {
        return await rest.request('/profile/value', 'PUT', payload, 'value')
    },
    
    async address(payload)  {
        const data = {
            first_name: payload.first_name,
            last_name: payload.last_name,
            country: payload.country,
            city: payload.city,
            address: payload.address,
            vat_registered_business: Number(payload.vat_registered_business),
            vat_number: payload.vat_number,
            company_name: payload.company_name
        }
        return await rest.request('/profile/address', 'PUT', data, 'address')
    },
    
    async updatePassword(payload)  {
        const data = {
            password: payload.password,
            new_password: payload.newPassword,
            new_password_confirmation: payload.confirmPassword,
            _method: "PUT",

        }
        return await rest.request('/profile/password', 'POST', data, 'updatePassword')
    },
    
    async updateAvatar(payload)  {
        const data = {
            avatar: payload.avatar,
            _method: "PUT",

        }
        return await rest.request('/profile/avatar', 'POST', data, 'updatePassword')
    },
    
    async addressCode(payload)  {
        const data = {
            publicCode: payload.publicCode,
            code: payload.code,
        }
        return await rest.request('/profile/address', 'POST', data, 'addressCode')
    },

}