<template>
    <section class="py-[60px] bg-white-100 section align-items-start flex-auto">
        <div class="container mx-0 container-dash">
            <div class="steps-flex flex flex-col">
                <div
                    class="custom-columns custom-columns--50 mb-0-576 sm:mb-[-50px] mb-[-20px]"
                >
                    <div
                        class="custom-col-6 justify-center flex flex-col custom-col-12-1200 oder--1-1200 sm:pb-[50px] pb-[0px] align-items-center-1200 text-center-1200"
                    >
                        <div
                            class="heading-block mw-full-576 align-items-center-1200 text-center-1200"
                        >
                            <div class="max-w-max flex mb-[15px]">
                                <img
                                    src="@/assets/img/icons/forgot-password-sent.svg"
                                    alt=""
                                />
                            </div>
                            <div class="flex items-center">
                                <h2 class="heading heading-2 font-bold">
                                    Waiting for email confirmation
                                </h2>
                            </div>
                            <div class="mt-[20px] mw-440">
                                <p
                                    class="def-text--2 font-semibold text-secondary-500"
                                >
                                    In order to start your free trial, please
                                    confirm your email address. We have just
                                    sent you a confirmation code.
                                </p>
                            </div>
                        </div>
                        <form
                            class="form w-full mt-[30px]"
                            method="post"
                            action="#"
                        >
                            <div class="row">
                                <div class="col-12 mb-[12px]">
                                    <div class="form-group">
                                        <label class="input-wraper" :class="{'error': codeError}">
                                            <input
                                                type="text"
                                                class="input font-semibold"
                                                placeholder="Confirmation code"
                                                v-model="code"
                                                @input="codeError = false"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button
                                        :class="{'btn-disabled': !code || loading}"
                                        @click.prevent="onVerify"
                                        type="submit"
                                        class=" btn btn--lg2 btn--primary rounded-[8px] w-full justify-between"
                                    >
                                        <span
                                            class="info text-[14px] font-bold"
                                        >
                                            Submit
                                        </span>
                                        <span class="ico ico-24 ml-[10px]">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3.31818 11.6361L21 11.6361"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M13.3636 19.2727L21 11.6364L13.3636 4"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        class="custom-col-6 mw-full-576 heading-block flex flex-col custom-col-12-1200 sm:pb-[50px] pb-[0px]"
                    >
                        <div
                            class="social-card pt-[18px] pb-[24px] h-full sm:bg-secondary-100 p-0 sm:px-[30px] rounded-[14px] flex"
                        >
                            <div
                                class="social-card--item w-full justify-between flex flex-col"
                            >
                                <div class="row">
                                    <div class="col-12 mb-[12px]">
                                        <div
                                            class="social-card bg-primary-100 rounded-[8px] flex items-center p-[18px] pb-[9px]"
                                        >
                                            <div
                                                class="social-card--item flex items-center pb-[9px]"
                                            >
                                                <span class="ico ico-25"
                                                    ><img
                                                        src="@/assets/img/tiktok-logo.svg"
                                                /></span>
                                                <div
                                                    class="flex flex-col ml-[12px]"
                                                >
                                                    <p
                                                        class="info def-text--1 font-semibold"
                                                    >
                                                        {{ getProduct.product_units }} TikTok {{ getProdName }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="social-card--item flex items-center pb-[10px]"
                                            >
                                                <span
                                                    class="badge bg-teal rounded-[6px]"
                                                >
                                                    <span class="info"
                                                        >FREE</span
                                                    >
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-[12px]">
                                        <div class="form-group">
                                            <div
                                                class="input-wraper input-wrapper-ico--left input-disable"
                                            >
                                                <span
                                                    class="user-name-30x30 bg-primary-500 text-white-100"
                                                >
                                                    <img
                                                        :src="getUserData.avatar_168x168.url_list[0]"
                                                    />
                                                </span>
                                                <input
                                                    type="text"
                                                    class="input font-semibold"


                                                    placeholder="Your username"
                                                    :value="getUserData.nickname"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-[12px]">
                                        <div class="form-group">
                                            <div
                                                class="input-wraper fill-input input-wraper--label input-disable"
                                            >
                                                <input
                                                    type="text"
                                                    class="input font-semibold"
                                                    placeholder="Your email"
                                                    :value="getUserEmail"
                                                />
                                                <router-link
                                                    to="/account-details"
                                                    class="text-primary-500 h-full btn mr-[20px]"
                                                >
                                                    <span
                                                        class="info text-[14px] font-semibold"
                                                    >
                                                        Change
                                                    </span>
                                                </router-link>
                                                <div class="label-input">
                                                    <span>Your email</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <p
                                            class="text-[14px] font-semibold text-secondary-500"
                                        >
                                            Didn't get code?
                                            <a v-if="!emailTimer"
                                                @click.prevent="resendEmail"
                                                :class="{'disabled-resend': disableResend}"
                                                href="#"
                                                class="text-primary-500 btn-resend"
                                            >
                                                Resend
                                            </a>
                                            <a v-if="emailTimer"
                                               @click.prevent=""
                                               href="#"
                                               class="text-primary-500 btn-resend"
                                            >
                                                Resend in
                                                <span class="counter">
                                                    0:{{ getTimerCount }}s
                                                </span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {profileApi} from "@/api/profileApi";
import {ordersApi} from "@/api/ordersApi";

export default {
    props: ['productGroup', 'config', 'userManualData', 'orderHash'],
    data() {
        return {
            emailTimer: null,
            intervalId: null,
            disableResend: false,
            code: null,
            loading: false,
            codeError: false,
        }
    },
    computed: {
        getUserEmail() {
            return this.userManualData?.email || ''
        },
        getUserData() {
            return this.config.userData
        },
        getProduct() {
            return this.config.selectedProduct
        },
        getProdName() {
            const identifier = this.config.groupIdentifier

            const obj = {
                'tiktok.likes': 'Likes',
                'tiktok.followers': 'Followers',
                'tiktok.views': 'Views',
            }

            return obj[identifier]
        },
        getTimerCount() {
            const count = this.emailTimer
            return count < 10 ? '0' + count : count
        }
    },
    methods: {
        async resendEmail() {
                try {

                    this.disableResend = true

                    const response = await ordersApi.resendTrialConfirmationData({
                        email: this.userManualData.email,
                        hash: this.orderHash
                    });

                    console.log(response, 'res')

                        if (response?.confirmation_send_at) {
                            clearInterval(this.intervalId);
                            this.emailTimer = 59;
                            this.startTimer();
                        } else {
                            console.error('Failed to resend email confirmation');
                        }
                } catch (error) {
                    console.error('Error during resendEmail:', error);
                } finally {
                    this.disableResend = false
                }
        },
        startTimer() {
            this.intervalId = setInterval(() => {
                this.emailTimer -= 1;
                window.localStorage.setItem('emailTimer', this.emailTimer);

                if (this.emailTimer <= 0) {
                    clearInterval(this.intervalId);
                    window.localStorage.removeItem('emailTimer');
                }
            }, 1000);
        },
        async onVerify() {
            if (!this.code) return

            this.loading = true



            try {

                const res = await ordersApi.verifyTrial({
                    hash: this.orderHash,
                    code: this.code
                })


                if (res.success) {
                    this.$emit('onSuccess')
                } else {
                    this.codeError = true
                }
            } catch (e) {
                console.log(e, '112')
                this.codeError = true
            } finally {
                this.loading = false
            }


        }
    },
    mounted() {
        const savedTimer = window.localStorage.getItem('emailTimer');
        if (savedTimer) {
            this.emailTimer = parseInt(savedTimer, 10);
            this.startTimer();
        }
    }

}

</script>

<style>
.disabled-resend {
    pointer-events: none;
}
</style>