
export function dateFilter(date) {
    date = new Date(Date.parse(date));
    const shortMonths = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]

    return `${shortMonths[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export function numberWithSpaces(x) {
    if(!x) return ''
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function floatNumberWithSpaces(x) {
    // console.log('x', x)
    if(!x) return ''
    var parts = (parseFloat(x)).toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    
    return parts.join(".");
}

export function numberWithComas(x) {
    if(!x) return ''
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function formatCardNumber(x) {
    x = x.toString();

    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = x.replace(/[^\d]/g, '')

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter(group => !!group).join('-')
    )
};

export function hideCardNumber(x) {
    const fomattedVal = formatCardNumber(x);

    return fomattedVal.split(' ').map((part, index) => index === 3 ? part : part.replace(/[0-9]/g, "*")).join(' ');
};
export function thousandFilter(x) {
    let number=x
    if (number < 1000) {
        return number;
      } else if (number >= 1000 && number < 1_000_000) {
        return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      } else if (number >= 1_000_000 && number < 1_000_000_000) {
        return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
      } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
        return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
      } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
        return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
      }
}

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};