import Vue from 'vue'
import VueRouter from 'vue-router'
import ServicePage from '../views/ServicePage.vue';
import FreeTrial from '../views/FreeTrial.vue';
import CheckoutFreeTrials from '../views/CheckoutFreeTrials.vue';
import FreeTrialUsed from '../views/FreeTrialUsed.vue';
import FreeTrialSuccesss from '../views/FreeTrialSuccess.vue';
import WaitingForEmail from '../views/WaitingForEmail.vue';
import Oreders from '../views/Orders.vue';
import OrderDetails from '../views/OrderDetails.vue';
// import ContactUs from '../views/ContactUs.vue';
const ContactUs = () => import('../views/ContactUs.vue');
// import Affiliate from '../views/Affiliate/Affiliate.vue';
const Affiliate = () => import('../views/Affiliate/Affiliate.vue');
// import TermsAndPrivacy from '../views/TermsAndPrivacy.vue';
const TermsAndPrivacy = () => import('../views/TermsAndPrivacy.vue');
// import Settings from '../views/Settings.vue';
const Settings = () => import('../views/Settings.vue');
// import Cart from '../views/Cart/Cart.vue';
const Cart = () => import('../views/Cart/Cart.vue');
import Blogs from '../views/Blogs.vue';
import BlogPost from '../views/BlogPost.vue';
import Discounts from '../views/Discounts.vue';
import Reviews from '../views/Reviews.vue';
import SplashScreen from '../views/SplashScreen.vue';
import WithoutLayout from '../components/Layout/WithoutLayout.vue';
import AccountDetails from '../views/AccountDetails.vue';
import Invoices from '../views/Invoices.vue';
import MyFunds from '../views/Funds/MyFunds.vue';
import BalanceHistory from '../views/Funds/BalanceHistory.vue';
import FailedPayment from '../views/Payment/FailedPayment.vue';
import SuccessPayment from '../views/Payment/SuccessPayment.vue';
import SmallLayout from '../components/Layout/SmallLayout.vue';
import AffiliateLanding from '@/views/Affiliate/AffiliateLanding.vue'
import AffiliateDashboardOrderDetails from '@/views/Affiliate/AffiliateDashboardOrderDetails.vue'
import AffiliateDashboardWithdrawFunds from '@/views/Affiliate/AffiliateDashboardWithdrawFunds.vue'
import FreeTrialList from '@/views/FreeTrialList.vue'
// import YTThumbnailDownloaderOutput from '@/views/Tools/YTThumbnailDownloaderOutput.vue'
// import YTThumbnailDownloader from '@/views/Tools/YTThumbnailDownloader.vue'
const YTThumbnailDownloader = () => import('@/views/Tools/YTThumbnailDownloader.vue');
// import FindIGUserID from '@/views/Tools/FindIGUserID.vue'
const FindIGUserID = () => import('@/views/Tools/FindIGUserID.vue');
// import FindYouTubeChannelId from '@/views/Tools/FindYouTubeChannelId.vue'
const FindYouTubeChannelId = () => import('@/views/Tools/FindYouTubeChannelId.vue');
// import HashtagCounter from '@/views/Tools/HashtagCounter.vue'
const HashtagCounter = () => import('@/views/Tools/HashtagCounter.vue');
// import EngagementCalculator from '@/views/Tools/EngagementCalculator.vue'
const EngagementCalculator = () => import('@/views/Tools/EngagementCalculator.vue');
// import FullSizeProfilePictureViewer from '@/views/Tools/FullSizeProfilePictureViewer.vue'
const FullSizeProfilePictureViewer = () => import('@/views/Tools/FullSizeProfilePictureViewer.vue');
// import VideoDownloaderTwo from '@/views/Tools/VideoDownloaderTwo.vue'
// import IgAudit from '@/views/Tools/IgAudit.vue'
const IgAudit = () => import('@/views/Tools/IgAudit.vue');
// import FontGenerator from '@/views/Tools/FontGenerator.vue'
const FontGenerator = () => import('@/views/Tools/FontGenerator.vue');
// import FakeTwitterPostGenerator from '@/views/Tools/FakeTwitterPostGenerator.vue'
const FakeTwitterPostGenerator = () => import('@/views/Tools/FakeTwitterPostGenerator.vue');
// import FakeIgPostGenerator from '@/views/Tools/FakeIgPostGenerator.vue'
const FakeIgPostGenerator = () => import('@/views/Tools/FakeIgPostGenerator.vue');
// import BioMakerOutput from '@/views/Tools/BioMakerOutput.vue'
import BioMaker from '@/views/Tools/BioMaker.vue'
import CaptionGenerator from '@/views/Tools/CaptionGenerator.vue'
import UsernameGenerator from '@/views/Tools/UsernameGenerator.vue'
import InstagramLineBreakGenerator from '@/views/Tools/InstagramLineBreakGenerator.vue'
import ProfileViewerOutput from '@/views/Tools/ProfileViewerOutput.vue'
import ProfileViewer from '@/views/Tools/ProfileViewer.vue'
import ToolsTemplate from '@/views/Tools/ToolsTemplate.vue'
import HashtagGenerator from '@/views/Tools/HashtagGenerator.vue'
import ViewInstagramProfile from '@/views/Tools/ViewInstagramProfile.vue'
import VideoDownloader from '@/views/Tools/VideoDownloader.vue'
import TikTokVideoDownloader from '@/views/Tools/TikTokVideoDownloader.vue'
import LiveFollowersCounter from '@/views/Tools/LiveFollowersCounter.vue'

import Panel from '@/components/Panel/Panel.vue'
import FreeTrialContainer from "@/views/Tools/FreeTrialContainer.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/service-page/likes'
  },
  {
    path: '/service-page/:type',
    component: ServicePage
  },

  {
    path: '/tiktok/:type',
    component: Panel,
    meta: {
      hideSidebar: true,
      hideHeader: true,
      showSmallMobileHeader: true,
      layout: SmallLayout,
    }
  },

  {
    path: '/welcome',
    component: SplashScreen,
    meta: {
        layout: WithoutLayout
      },
  },
  {
    path: '/free-trial',
    component: FreeTrial,
  },

  {
    path: '/free-trial-process/:any',
    component: FreeTrialContainer,
  },

  {
    path: '/free-trials-checkout',
    component: CheckoutFreeTrials,
  },
  {
    path: '/free-trials-used',
    component: FreeTrialUsed,
  },
  {
    path: '/free-trials-success',
    component: FreeTrialSuccesss,
  },
  {
    path: '/waiting-email',
    component: WaitingForEmail,
  },
  {
    path: '/orders',
    component: Oreders,
    meta: {hideFooter: true},
  },
  {
    path: '/order-details/:id',
    component: OrderDetails,
    meta: {hideFooter: true},
  },
  {
    path: '/contact-us',
    component: ContactUs,
    meta: {hideFooter: true},
  },
  {
    path: '/affiliate',
    component:Affiliate,
    meta: {hideFooter: true},
  },
  {
    path: '/terms-and-privacy',
    component: TermsAndPrivacy,
    meta: {hideFooter: true},
  },
  {
    path: '/settings',
    component: Settings,
    meta: {hideFooter: true},
  },
  {
    path: '/cart',
    component: Cart,
    meta: {
      hideFooter: true,
      hideSidebar: true,
      hideHeader: true,
    },
  },
  {
    path: '/blogs',
    component: Blogs,
    meta: {
      hideSidebar: true,
      hideHeader: true,
    },
  },
  {
    path: '/blogs/blog-post/:id',
    component:  BlogPost,
    meta: {
      hideSidebar: true,
      hideHeader: true,
    },
  },
  {
    path: '/discounts',
    component: Discounts,
    meta: {
      hideFooter: true,
    }
  },
  {
    path: '/account-details',
    component: AccountDetails,
    meta: {
      hideFooter: true,
    }
  },
  {
    path: '/my-invoices',
    component: Invoices,
    meta: {
      hideFooter: true,
    }
  },
  {
    path: "/add-funds",
    component: MyFunds,
    meta: {
      hideFooter: true,
    }
  },
  {
    path: "/add-funds/balance-history",
    component: BalanceHistory,
    meta: {
      hideFooter: true,
    }
  },

  {
    path: '/reviews',
    component: Reviews,
    meta: {
      hideFooter: true,
    }
  },
  {
    path: '/failed-payment',
    component: FailedPayment,
    meta: {
      hideSidebar: true,
      hideHeader: true,
      showSmallMobileHeader: true,
      layout: SmallLayout,
    }
  },
  {
    path: '/success-payment',
    component: SuccessPayment,
    meta: {
      hideSidebar: true,
      hideHeader: true,
      showSmallMobileHeader: true,
      layout: SmallLayout,
    }
  },
  {
    path: '/affiliate-landing',
    component: AffiliateLanding,
    meta: {
      hideSidebar: true,
      hideHeader: true,
      showSmallMobileHeader: true,
    }
  },
  {
    path: '/affiliate/order/:id',
    component: AffiliateDashboardOrderDetails,
    meta: {
    //   hideSidebar: true,
    //   hideHeader: true,
    //   showSmallMobileHeader: true,
    hideFooterMobile: true
    }
  },
  {
    path: '/affiliate/withdraw-funds',
    component: AffiliateDashboardWithdrawFunds,
    meta: {
    //   hideSidebar: true,
    //   hideHeader: true,
    //   showSmallMobileHeader: true,
    hideFooterMobile: true
    }
  },
  {
    path: '/free-trial-list',
    component: FreeTrialList,
    meta: {
      showSmallMobileHeader: true,
    }
  },
  // {
  //   path: '/tools/yt-thumbnail-downloader-output',
  //   component: YTThumbnailDownloaderOutput,
  //   meta: {
  //       hideFooter: true
  //   }
  // },
  {
    path: '/tools/yt-thumbnail-downloader',
    component: YTThumbnailDownloader,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/find-ig-user-id',
    component: FindIGUserID,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/find-youtube-channel-id-info',
    component: FindYouTubeChannelId,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/hashtag-counter',
    component: HashtagCounter,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/engagement-calculator',
    component: EngagementCalculator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/full-size-profile-picture-viewer-downloader',
    component: FullSizeProfilePictureViewer,
    meta: {
      title: "Full Size Profile Picture Viewer & Downloader",
      hideFooter: true,
      showSmallMobileHeader: true
    }
  },
  // {
  //   path: '/tools/video-downloader-2',
  //   component: VideoDownloaderTwo,
  //   meta: {
  //       hideFooter: true,
  //       showSmallMobileHeader: true
  //   }
  // },
  {
    path: '/tools/tiktok-audit',
    component: IgAudit,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/font-generator',
    component: FontGenerator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/fake-twitter-post-generator',
    component: FakeTwitterPostGenerator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/fake-ig-post-generator',
    component: FakeIgPostGenerator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  // {
  //   path: '/tools/bio-maker-output',
  //   component: BioMakerOutput,
  //   meta: {
  //       hideFooter: true,
  //       showSmallMobileHeader: true
  //   }
  // },
  {
    path: '/tools/bio-maker',
    component: BioMaker,
    meta: {
      title: "Bio Maker",
      hideFooter: true,
      showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/caption-generator',
    component: CaptionGenerator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/username-generator',
    component: UsernameGenerator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  {
    path: '/tools/instagram-line-break-generator',
    component: InstagramLineBreakGenerator,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  // {
  //   path: '/tools/profile-viewer-output',
  //   component: ProfileViewerOutput,
  //   meta: {
  //       hideFooter: true,
  //   }
  // },
  {
    path: '/tools/profile-viewer',
    component: ProfileViewer,
    meta: {
        hideFooter: true,
        showSmallMobileHeader: true
    }
  },
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

	// TOOLS
	{
		path: '/tools/template',
		component: ToolsTemplate
	},
  {
    path: '/tools/hashtag-generator',
    component: HashtagGenerator,
    meta: { hideFooter: true },
  },
  // {
  //   path: '/tools/view-instagram-profile',
  //   component: ViewInstagramProfile,
  //   meta: {hideFooter: true},
  // },
  {
    path: '/tools/video-downloader',
    component: VideoDownloader,
    meta: {hideFooter: true},
  },
  {
    path: '/tools/tiktok-video-downloader',
    component: TikTokVideoDownloader,
    meta: {hideFooter: true},
  },
  {
    path: '/tools/followers-counter',
    component: LiveFollowersCounter,
    meta: {hideFooter: true},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
