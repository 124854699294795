<template>
    <img :src="src" alt="" v-if="src" :class="size === 'sm' ? 'icon_24_24' : ''">
    <span v-else style="width:24px;height:24px;background: #dadada;border-radius: 4px;"></span>
</template>
<script>
export default {
    props: {
        platform: {
            type: String,
            default: () => 'instagram', //instagram, twitter, facebook, youtube, soundcloud, spotify, tiktok
        },
        size: {
            type: String,
            default: () => 'sm' // sm, lg
        },
        imgClass:{
            type:String,
            default:()=>''
        }
    },
    computed: {
        src: function() {
            if (this.size === 'sm' || this.size==='qualityFix') {
                switch(this.platform.toLowerCase()) {
                    case 'instagram':
                        return `/img/instagram.webp`;
                    case 'twitter':
                        return `/img/twitter.webp`;
                    case 'facebook':
                        return `/img/facebook.webp`;
                    case 'youtube':
                        return `/img/youtube-sm.webp`;
                    case 'soundcloud':
                        return `/img/soundcloud.webp`;
                    case 'spotify':
                        return `/img/spotify.webp`;
                    case 'tiktok':
                        return `/img/tiktok.webp`;
                    case 'threads':
                        return `/img/threads.png`;
                    default:
                        return false;
                }
            } 
            else if (this.size === 'svg') {
                // alert('SVG USE')
                switch(this.platform.toLowerCase()) {
                    case 'instagram':
                        return `/img/ico-big-instagram.svg`;
                    case 'twitter':
                        return `/img/ico-big-twitter.svg`;
                    case 'facebook':
                        return `/img/ico-big-facebook.svg`;
                    case 'youtube':
                        return `/img/ico-big-youtube.svg`;
                    case 'soundcloud':
                        return `/img/ico-big-soundcloud.svg`;
                    case 'spotify':
                        return `/img/ico-big-spotify.svg`;
                    case 'tiktok':
                        return `/img/ico-big-tiktok.svg`;
                    case 'threads':
                        return `/img/threads.png`;
                    default:
                        return false;
                }
            } 
            else {
                switch(this.platform.toLowerCase()) {
                    case 'instagram':
                        // return `/img/ico-big-instagram.svg`;
                        return `/img/ico-big-instagram.png`;
                    case 'twitter':
                        // return `/img/ico-big-twitter.svg`;
                        return `/img/ico-big-twitter.png`;
                    case 'facebook':
                        // return `/img/ico-big-facebook.svg`;
                        return `/img/ico-big-facebook.png`;
                    case 'youtube':
                        // return `/img/ico-big-youtube.svg`;
                        return `/img/ico-big-youtube.png`;
                    case 'soundcloud':
                        // return `/img/ico-big-soundcloud.svg`;
                        return `/img/ico-big-soundcloud.png`;
                    case 'spotify':
                        // return `/img/ico-big-spotify.svg`;
                        return `/img/ico-big-spotify.png`;
                    case 'tiktok':
                        // return `/img/ico-big-tiktok.svg`;
                        return `/img/ico-big-tiktok.png`;
                    case 'threads':
                        return `/img/ico-big-threads.png`;
                    default:
                        return false;
                }
            }
        }
    },
}
</script>