<template>
    <!-- <div> -->
    <div class="wrapper lg-bg-gray-300" >
        <header class="header-dash header-custom bg-black-100 hidden lg:block">
            <div class="container-px--lg">
                <div class="header-dash-body">
                    <ul class="header-dash-list header-dash-list-2">
                        <li>
                            <a id="headerGoBack" href="#" class="btn w-[40px] h-[40px] bg-color1 rounded-[11px] color-white">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.6818 11.6366L3 11.6366" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M10.6364 19.2727L3 11.6364L10.6364 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="" class="btn px-[24px] py-[14px] bg-primary-500 rounded-[18px] text-white-100">
                                Quick order
                            </a>
                        </li> -->
                    </ul>
                    <div class="logo flex"><img src="@/assets/img/tinkws-logo.svg" alt=""></div>
                    <div class="mw-content">
                        <router-link to="/reviews">
                            <div class="flex text-center items-center mx-[-4px]">
                                <p class="def-text--5 font-semibold px-[4px] color-white">4.7 / 5</p>
                                <div class="rating-input px-[4px]" style="pointer-events:none;">
                                    <input type="radio" checked="" name="rating" id="rating-5">
                                    <label for="rating-5"></label>
                                    <input type="radio" name="rating" id="rating-4">
                                    <label for="rating-4"></label>
                                    <input type="radio" name="rating" id="rating-3">
                                    <label for="rating-3"></label>
                                    <input type="radio" name="rating" id="rating-2">
                                    <label for="rating-2"></label>
                                    <input type="radio" name="rating" id="rating-1">
                                    <label for="rating-1"></label>
                                </div>
                                <p class="def-text--5 font-semibold px-[4px] color-white">
                                    2131 reviews
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </header>

<!--        <SmallMobileHeader v-if="showSmallMobileHeader" :dataprops="dataprops" :withOutNav="true" />-->

        <div class="flex-auto">
            <router-view :dataprops="dataprops" />
        </div>

        <Popup/>

        <Footer class="layout__hide-footer-mobile" />
    </div>

    
    <!-- </div> -->
</template>

<script>
import Popup from '@/components/Modals/popup.vue'
import SmallMobileHeader from '@/components/Parts/SmallMobileHeader.vue'
import Footer from '../Parts/Footer.vue'

export default {
    props: ['dataprops'],
    data() {
        return {}
    },
    components:{
        Popup,
        Footer,
        SmallMobileHeader
    },
    computed:{
        showSmallMobileHeader(){
            return this.$route.meta.showSmallMobileHeader ? true : false
        },
    },
}
</script>