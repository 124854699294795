<template>
    <section class="lg:py-[60px] lg:bg-[#EEF3FF] section flex flex-col flex-auto" :class="{'isPopUp': isPopUp}">
        <div class="container-px--lg flex flex-col flex-auto">
            <div class="flex items-center justify-center relative mb-4 mt-[14px] lg:hidden" >
                <a @click.prevent="(e) => $emit('headerGoBackPopUp', e)" href="#" class="btn w-[40px] h-[40px] rounded-[11px] left-0 absolute">
                    <img src="@/assets/img/icons/left-arrow-long.svg" alt="">
                </a>
                <span class="def-text--1 font-semibold">New order</span>
            </div>
            <div class="steps-flex flex flex-auto flex-col">
                <div class="custom-columns flex-auto" :class="{'custom-columns--50':!isPopUp }">
                    <div class="custom-col-6 steps-flex--form flex flex-col custom-col-12-1200" :class="{'custom-col-12': config.freePanel}">

                        <div class="bg-white-100 rounded-[10px] flex flex-col" :class="{'flex-auto': !isFollowers, 'px-8 py-10':!isPopUp}">

                            <template v-if="!isFollowers">
                            <div class="p-9 flex items-center bg-primary-100 rounded-[8px] mb-[13px] justify-center relative">
                                <span class="rounded-full absolute left-9">
                                    <img class="w-[18px] h-[18px]" :src="getUserAvatar" alt="">
                                </span>
                                <span class="font-semibold">{{ selectedMedia.count }} posts selected</span>
                            </div>
                            <div>
                                <div class="row gutters-10">
                                    <div 
                                        v-for="(item, idx) in getMedia.slice(0, showMore.postsShowed)" class="col-lg-3 col-4 my-[5px]" 
                                        :class="{'disable-media-post': maxItemSelected && !Object.keys(selectedMedia.addedItems).includes(item.id) }" :key="idx"
                                    >
                                        <label class="post-select" >
                                            <input type="checkbox" name="photo" class="choice-input" @change="addPostToChoose(item)" style="display:none">

                                            <div class="post-selected-cover" :class="{'max-h-[130px]':isPopUp}">
                                                <div class="post_select__img" data-count="5" :style="{ 'background-image': 'url(' + item.image + ')' }">
                                                    <!-- <img :src="item.image" alt=""> -->
                                                </div>
                                                <span class="post-selected__count">{{ getPostProductCount }}</span>
                                            </div>


                                        </label>
                                    </div>

                                </div>
                            </div>
                            <a v-if="showMore.isTrue" href="#" class="btn flex-col text-center def-text--1 text-primary-500 mt-[30px]"
                                @click.prevent="showMorePosts">
                                <span class="font-semibold">
                                    See more
                                </span>
                                <span class="ico ico-20 mt-[4px]">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5265 15.2603C11.4578 15.2322 11.3954 15.1906 11.343 15.138L5 8.792L5.792 8L11.743 13.951L17.694 8L18.486 8.792L12.139 15.138C12.0871 15.1901 12.0253 15.2314 11.9574 15.2595C11.8894 15.2877 11.8166 15.3021 11.743 15.302C11.6688 15.3026 11.5952 15.2885 11.5265 15.2603Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </a>

                            <!-- <SpinnerLoading v-if="isLoading"/> -->

                           <div class="switch-toggler-container">
                               <div class="flex-wrap flex items-center justify-between mb-6">
                                   <p class="def-text&#45;&#45;1 font-[600] mb-[10px] mr-[10px] text-text">
                                   Advanced options
                                   </p>
                                   <div class="mb-[10px]">


                                     <label class="switch">
                                       <input
                                           type="checkbox"
                                           class="switch__field"
                                           v-on:change="showAdvanceOptions"
                                       />
                                       <span class="switch__slider"></span>
                                     </label>
                                   </div>
                               </div>
                               <div class="switch-toggler-body custom-hidden-body" ref="toggleBody" :class="{'show-body':inputedData.advancedOptionCheckbox}" >
                                   <div class="form-group mb-5">
                                       <div class="select-multiple filled select-multiple&#45;&#45;label not&#45;&#45;lefticon">
                                           <div class="select-search up-price">
                                               <div class="label-input">
                                                   <span>Gender targeting</span>
                                               </div>

                                               <SelectBox
                                                   key="select-1"
                                                   identifier='select-price'
                                                   :options="genderOptions"
                                                   :value="inputedData.selectedGender"
                                                   @change="(value)=>{inputedData.selectedGender = value;}"
                                                />
                                          </div>

                                            <div class="select-arrow">
                                                <span class="ico">
                                                    <img src="@/assets/img/icons/ico-arrowdown.svg">
                                                </span>
                                            </div>
                                       </div>
                                    </div>
                                    <div class="form-group mb-5">
                                        <div class="select-multiple filled select-multiple&#45;&#45;label not&#45;&#45;lefticon">
                                            <div class="select-search up-price">
                                              <div class="label-input">
                                                    <span>Country Targeting</span>
                                               </div>

                                                <SelectBox
                                                  key="select-1"
                                                   identifier='select-price'
                                                    :options="countryOptions"
                                                    :value="inputedData.selectedCountry"
                                                    @change="(value)=>{inputedData.selectedCountry = value;}"
                                                />

                                            </div>
                                            <div class="select-arrow">
                                                <span class="ico">
                                                    <img src="@/assets/img/icons/ico-arrowdown.svg">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           </div>

                            <div v-if="productGroup?.commentLists?.length > 0" class="select-multiple filled select-multiple&#45;&#45;label not&#45;&#45;lefticon">
                                           <div class="select-search up-price">
                                               <div class="label-input">
                                                   <span>Gender targeting</span>
                                               </div>

                                               <SelectBox
                                                   key="select-1"
                                                   identifier='select-price'
                                                   :options="genderOptions"
                                                   :value="inputedData.selectedGender"
                                                   @change="(value)=>{inputedData.selectedGender = value;}"
                                                />
                                          </div>

                                            <div class="select-arrow">
                                                <span class="ico">
                                                    <img src="@/assets/img/icons/ico-arrowdown.svg">
                                                </span>
                                            </div>
                                       </div>
                            </template>

                            <template v-else>
                                <div class="p-9 flex items-center bg-primary-100 rounded-[8px] mb-[13px] relative">
                                    <span class="rounded-full absolute left-9">
                                        <img class="w-[35px] h-[35px]" :src="getUserAvatar" alt="">
                                    </span>
                                    <span class="pl-[60px] font-semibold">{{ config.userData.nickname }}</span>
                                    <span class="pl-[15px] font-semibold">{{ config.userData.follower_count }} + {{ config.selectedProduct.product_units }}  followers</span>
                                </div>
                            </template>
                            <a @click.prevent="nextStep" href="#" class="btn btn--secondary btn-md-double btn-double panel-btn rounded-[18px] add-to-cart-btn" :class="{'disabled': !selectedMedia.count && !isFollowers, '!mt-auto': config.freePanel}">
                                <span class="info">{{ isFreePanel ? 'Free' : '$' + config.selectedProduct.base_price }}</span>
                                <span class="info">{{ isFreePanel ? 'Start Trial' : 'Add to cart' }}</span>
                            </a>
                        </div>
                    </div>
                    <div v-if="!isPopUp && !config.freePanel" class="custom-col-6 steps-flex--text align-items-center-1200 flex-col custom-col-12-1200 text-center-1200 oder--1-1200 pb-[50px] pt-8 hidden lg:flex">
                        <div class="max-w-screen sm:max-w-[500px] fixed">
                            <SmallSwiper :isSecondVariant="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        :postData="getPostDataForModal"-->
<!--        :commentsPerPost="getPostProductCount"-->
<!--        :commentsData="getCommentsDataForModal"-->
        <AddCommentModal
            v-if="commentsModalState.open"
            :postData="getPostDataForModal"
            :commentsPerPost="getPostProductCount"
            :commentsData="getCommentsDataForModal"
            @close="commentsModalState = {
                open: false,
                id: null
            }"
            @fillComment="fillComment"
        />
    </section>
</template>

<script>
import SmallSwiper from '@/components/Base/SmallSwiper.vue';
// import SpinnerLoading from '@/components/Base/SpinnerLoading.vue';
import SelectBox from '@/components/Base/SelectBox.vue';
import AddCommentModal from "@/components/Modals/AddCommentModal.vue";
import {createLogger} from "vuex";

export default {
    components: {
        AddCommentModal,
        SmallSwiper,
        // SpinnerLoading,
        SelectBox
    },
    props: ['config', 'productGroup', 'isPopUp'],
    data() {
        return {
            showMore: {
                isTrue: false,
                postsShowed: 0,
                postsCount: 0
            },
            inputedData: {
                selectedGender: '',
                selectedCountry: '',
                advancedOptionCheckbox: false,

            },
            genderOptions: [
                {
                    label: 'Female',
                    value: 'Female',
                    price: '+$2.53'
                },
                {
                    label: 'Male',
                    value: 'Male',
                    price: '+$2.53'
                },
            ],
            countryOptions: [
                {
                    label: 'United States',
                    value: 'United States',
                    price: '+$2.53'
                },
                {
                    label: 'United States',
                    value: 'United States',
                    price: '+$2.53'
                },
            ],

            selectedMedia: {
                addedItems: {},
                comments: {},
                count: 0
            },

            commentsModalState: {
                open: false,
            },

            maxItemSelected: null,
            maxItemCount: null,

            setCart: [],

            addonAdded: false,
        }
    },
    methods: {
        showMorePosts() {
            if (this.showMore.postsShowed < this.showMore.postsCount) {
                this.showMore.postsShowed += 8;
            }

            if (this.showMore.postsShowed > this.showMore.postsCount) {
                this.showMore.isTrue = false
            }

        },
        showAdvanceOptions() {
            this.inputedData.advancedOptionCheckbox = !this.inputedData.advancedOptionCheckbox
            if (this.inputedData.advancedOptionCheckbox) {
                setTimeout(() => {
                    this.$refs.toggleBody.style.overflow = 'unset'
                }, 300)
            } else {
                this.$refs.toggleBody.style.overflow = 'hidden'
            }
        },
        addPostToChoose(item) {
            console.log('ITEM', item)
            if (this.selectedMedia.addedItems[item.id]) {
                this.selectedMedia.count = this.selectedMedia.count - 1
                delete this.selectedMedia.addedItems[item.id];
            } else {
                if (this.selectedMedia.count === this.maxItemCount) return
                this.selectedMedia.addedItems[item.id] = item
                this.selectedMedia.count = this.selectedMedia.count + 1
            }

            this.selectedMedia.count === this.maxItemCount ? this.maxItemSelected = true : this.maxItemSelected = false
            this.checkFilledComments()
        },
        fillComment(data) {
            this.selectedMedia.comments = {
                ...this.selectedMedia.comments,
                [data.key]: {...data.values}
            }
            console.log('here')
            this.checkFilledComments()
        },
        nextStep(event, filledTrue) {
            if (!this.selectedMedia.count && !this.isFollowers) {
                return
            }



            if (this.config.groupIdentifier.toLowerCase().includes('customcomments') && !filledTrue) {
                console.log('inside');
                this.checkFilledComments()
                return
            }

            const mediaComArr = Object.values(this.selectedMedia.addedItems)
            const timestamp = new Date().getTime()
            const prod = this.config.selectedProduct

            let selectedProductsForExtras = []

            if (mediaComArr.length) {

                const divUnits = Math.floor(prod.product_units / mediaComArr.length)

                console.log(divUnits, 'divUnits')
                const obj = {
                    data: {
                        addons: {},
                        // ...(this.productGroup.addons ? {addons: this.productGroup.addons} : {addons: {}}),
                        mediaData: [],
                        picture: this.config.userData['avatar_300x300']?.url_list[0],
                        userData: this.config.userData,
                        username: this.config.username
                    },
                    identifier: timestamp,
                    product_id: prod.id
                }

                const medProducts = []

                for (let i = 0; i < mediaComArr.length; i++) {
                    const el = mediaComArr[i];

                    const customCommentsArray = this.selectedMedia.comments[el.id]

                    medProducts.push({
                        // кастом комментс опционально
                        ...(customCommentsArray ? {customComments: Object.values(customCommentsArray)} : {}),
                        socialData: el.fullForCart,
                        units: divUnits.toFixed(0)
                    })

                    selectedProductsForExtras.push({
                        ...el,
                        ...(customCommentsArray ? {customComments: Object.values(customCommentsArray)} : {}),
                    })

                }

                obj.data.mediaData = medProducts

                console.log(obj, 'before set')
                // return
                this.setCart.push(obj)

            } else {
                const obj = {
                    data: {
                        addons: {},
                        mediaData: [{
                            socialData: this.config.userData,
                            units: prod.product_units
                        }],
                        picture: this.config.userData['avatar_300x300']?.url_list[0],
                        userData: this.config.userData,
                        username: this.config.username
                    },
                    identifier: timestamp,
                    product_id: prod.id
                }

                this.setCart.push(obj)
            }

            this.$emit('stepThree', {
                bought: this.setCart,
                // total: this.totalUpdated || this.total,
                selectedProductsForExtras,
            })

            this.setCart = []
        },
        checkFilledComments() {
            const remaining = this.selectedMedia.addedItems
            const filled = this.selectedMedia.comments

            const commentsPerPost = 5

            const arr = Object.entries(remaining)
            const postNeeded = arr.length

            const checkArr = []

            for (let i = 0; i < arr.length; i++) {

                const el = arr[i];
                const [key] = el

                if (!filled[key]) {
                    filled[key] = {}
                    this.commentsModalState = {
                        open: true,
                        id: key,
                    }
                    return
                }

                if (filled[key] && Object.keys(filled[key]).length >= commentsPerPost) {
                    checkArr.push(true)
                    if (checkArr.length !== postNeeded){
                        continue
                    }

                    this.commentsModalState = {
                        open: false,
                        id: null
                    }

                    this.nextStep(null,true)
                    console.log('filled')

                    return
                } else {
                    this.commentsModalState = {
                        open: true,
                        id: key,
                    }
                    return
                }
            }
        },
    },
    computed: {
        getMedia() {
            return this.config?.mediaData?.posts
        },
        getPostProductCount() {
            return Math.floor(this.selectedMedia.count ? (this.config.selectedProduct.product_units / this.selectedMedia.count) : this.config.selectedProduct.product_units)
        },
        getPostDataForModal() {
            // Выбор поста для заполнения комментариев
            return this.config?.mediaData?.posts.find(el => el.id + '' === this.commentsModalState.id + '')
        },
        getCommentsDataForModal() {
            // Выбор поста для заполнения комментариев
            return this.selectedMedia.comments[this.commentsModalState.id] || {}
        },
        isFollowers() {
            return this.config.groupIdentifier === 'tiktok.followers'
        },
        getUserAvatar() {
            return this.config.userData.avatar_168x168.url_list[0]
        },
        isFreePanel() {
            return this.config?.freePanel || false
        }
    },
    created() {
        this.maxItemCount = Math.floor(this.config.selectedProduct.product_units / this.productGroup?.min_units)

        this.showMore = {
            isTrue: (this.config?.mediaData?.posts.length > 9),
            postsShowed: 8,
            postsCount: this.config?.mediaData?.posts.length
        }

    },

}
</script>
<!--//.up-price .choices__list, .choices__list > * {-->
<!--//    overflow: unset !important;-->
<!--//}-->
<style scoped>
    .up-price .choices__inner .choices__item.choices__item--selectable .price {
        margin-top: -10px;
    }

    .add-to-cart-btn {
        margin-top: 20px;
    }

    .post-select {
        position: relative;
        /* width: 110px; */
        height: 170px;
    }

    .post-selected-cover {
        position: relative;
        width: inherit;
        height: 200px;
    }

    .post_select__img {
        position: relative;
        z-index: 2;
        height: inherit;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .post-selected__count {
        display: none;
    }

    label .choice-input:checked + .post-selected-cover .post-selected__count {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        color: #FFF;

        text-align: center;
        font-family: Montserrat;
        font-size: 21.373px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        z-index: 5;
    }


    label .choice-input:checked + .post-selected-cover::after {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        z-index: 1;
        margin: -1px;
        border-radius: inherit;
        background: linear-gradient(45deg, rgb(0, 242, 234), rgb(254, 44, 85));
    }

    label .choice-input:checked + .post-selected-cover::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.31) 100%);
        margin: 0;
    }

    .disable-media-post {
        opacity: 0.5;
        pointer-events: none;
    }

    .choices__list.choices__list--single {
        padding: 0;
    }
</style>

<style scoped>
    .custom-hidden-body {
        height: 0;
        overflow: hidden;
        transition: height 0.3s;
    }
    .show-body {
        height: 159px;
    }
    .isPopUp, .isPopUp .container-px--lg, .isPopUp .custom-col-6.steps-flex--form {
        padding: 0;
    }

</style>