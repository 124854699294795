<template>
    <section class="py-[60px] bg-white-100 section flex flex-auto">
        <div class="container mx-0 container-dash">
            <div
                class="heading-block items-center text-center max-w-[520px] mx-auto mw-full-576"
            >
                <div class="ico mb-[10px] err-logo">
                    <img src="@/assets/img/icons/icon-shape-red.svg" />
                </div>
                <div class="flex items-center">
                    <h2 class="heading heading-2 font-bold">
                        Your free trial failed!
                    </h2>
                </div>
                <div class="mt-[10px]">
                    <p
                        class="def-text--2 font-semibold ff-fotnFamilyMontserrat text-secondary-500"
                    >
                        {{errorMessage}}.
                    </p>
                </div>
            </div>
            <div class="mt-[50px] max-w-[330px] mw-full-576 mx-auto">
                <router-link
                    to="/free-trial"
                    class="btn btn--lg2 btn--primary rounded-[8px] w-full justify-between"
                >
                    <span class="info text-[14px] font-bold">
                        Free Trials
                    </span>
                    <span class="ico ico-24 ml-[10px]">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.31818 11.6361L21 11.6361"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                            <path
                                d="M13.3636 19.2727L21 11.6364L13.3636 4"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </span>
                </router-link>
                <router-link
                    to="/"
                    class="mt-[12px] btn btn--lg2 btn--primary-outline rounded-[8px] w-full"
                >
                    <span class="info text-[14px] font-bold"> New order </span>
                </router-link>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: ['errorMessage']
}
</script>

<style scoped>

.err-logo {
    transform: scale(2.5);
}

</style>