import restInstance from "@/lib/rest"

const rest = restInstance

export const otherApi = {
    async getNotifications() {
        return await rest.request('/notifications', 'GET', null, 'notifications')
    },

    async getMoreNotifications() {
        return await rest.request('/notifications/more', 'GET', null, 'moreNotifications')
    },

    async readNotifications() {
        return await rest.request('/notifications/mark-as-read', 'PUT', null, 'readNotifications')
    },
}