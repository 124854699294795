<template>
    <div id="billing-information" class="modal modal-16 modal--show">
        <div class="modal__dialog modal__dialog--591">
            <div class="modal__content" v-click-outside="closeModal">
                <div class="modal__header pb-[33px] px-[28px] sm:px-[59px]">
                    <h6 class="font-[700] text-[18px] leading-[24px]">Billing information</h6>
                    <a href="#" class="btn btn-close mobail-absolute" data-close-modal="" @click.prevent="closeModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4771 2.94469C17.9223 2.51467 18.1009 1.87788 17.9441 1.27907C17.7874 0.68025 17.3197 0.212602 16.7209 0.0558617C16.1221 -0.100878 15.4853 0.0776826 15.0553 0.522914L8.99914 6.57736L2.94469 0.522914C2.51467 0.0776826 1.87788 -0.100878 1.27907 0.0558617C0.68025 0.212602 0.212602 0.68025 0.0558617 1.27907C-0.100878 1.87788 0.0776826 2.51467 0.522914 2.94469L6.57736 8.99914L0.522914 15.0553C0.0776826 15.4853 -0.100878 16.1221 0.0558617 16.7209C0.212602 17.3197 0.68025 17.7874 1.27907 17.9441C1.87788 18.1009 2.51467 17.9223 2.94469 17.4771L8.99914 11.4226L15.0553 17.4771C15.4853 17.9223 16.1221 18.1009 16.7209 17.9441C17.3197 17.7874 17.7874 17.3197 17.9441 16.7209C18.1009 16.1221 17.9223 15.4853 17.4771 15.0553L11.4226 8.99914L17.4771 2.94469Z" fill="#3A4C66"></path>
                        </svg>
                    </a>
                </div>
                <div class="modal__body pb-[47px] px-[28px] sm:px-[59px]">
                    <div class="flex justify-between items-center mb-[17px]">
                        <span class="text-[14px] leading-[20px] font-[600]">Business Account</span>
                        
                        <label class="switch-default">
                            <input hidden type="checkbox" class="switch__field input-check-js" name="business-account" v-model="userBillingInfo.vat_registered_business.value">
                            <span class="switch__slider"></span>
                        </label>
                    </div>

                    


                    <div class="row">
                        <div class="mb-[12px] col-sm-6">
                            <label class="input-wraper input-wraper--label">
                                <input 
                                    type="text" 
                                    id="first-name" 
                                    class="input-text" 
                                    placeholder="First Name"
                                    v-model="userBillingInfo.first_name.value"
                                    :disabled="userBillingInfo.first_name.disabled"
                                    @input="disableBillingChangesBtn = false"
                                    @blur="onFieldBlur(userBillingInfo, ['first_name'])"
                                    :class="{ 'invalid': userBillingInfo.first_name.touched && !userBillingInfo.first_name.isValid }"
                                >
                                <div class="label-input">
                                    <span>First Name</span>
                                </div>
                            </label>
                        </div>
                        <div class="mb-[12px] col-sm-6">
                            <label class="input-wraper input-wraper--label">
                                <input 
                                    type="text" 
                                    id="last-name" 
                                    class="input-text" 
                                    placeholder="Last Name"
                                    v-model="userBillingInfo.last_name.value"
                                    :disabled="userBillingInfo.last_name.disabled"
                                    @input="disableBillingChangesBtn = false"
                                    @blur="onFieldBlur(userBillingInfo, ['last_name'])"
                                    :class="{ 'invalid': userBillingInfo.last_name.touched && !userBillingInfo.last_name.isValid }"
                                >
                                <div class="label-input">
                                    <span>Last Name</span>
                                </div>
                            </label>
                        </div>
    
                        <div class="form-group mb-[12px] col-sm-6">
                            <div class="select-multiple select-multiple--label not--lefticon" style="z-index:5;">
                                <div class="select-search">
                                    <div class="label-input">
                                        <span>Country</span>
                                    </div>
                                    <!-- <div class="choices select-input" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false"><div class="choices__inner"><select class="select-input choices__text choices__input" hidden="" tabindex="-1" data-choice="active"><option value="" data-custom-properties="[object Object]">Country</option></select><div class="choices__list choices__list--single"><div class="choices__item choices__placeholder choices__item--selectable" data-item="" data-id="1" data-value="" data-custom-properties="[object Object]" aria-selected="true" data-deletable="">Country<button type="button" class="choices__button" aria-label="Remove item: ''" data-button="">Remove item</button></div></div></div><div class="choices__list choices__list--dropdown" aria-expanded="false"><div class="choices__list" role="listbox"><div id="choices--2jg4-item-choice-1" class="choices__item choices__item--choice is-selected choices__placeholder choices__item--selectable is-highlighted" role="option" data-choice="" data-id="1" data-value="" data-select-text="Press to select" data-choice-selectable="" aria-selected="true">Country</div><div id="choices--2jg4-item-choice-2" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="@Name 1" data-select-text="Press to select" data-choice-selectable="">@Name 1</div><div id="choices--2jg4-item-choice-3" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="@Name 2" data-select-text="Press to select" data-choice-selectable="">@Name 2</div><div id="choices--2jg4-item-choice-4" class="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="4" data-value="@Name 3" data-select-text="Press to select" data-choice-selectable="">@Name 3</div></div></div></div> -->

                                    

                                    <SelectBox 
                                        key="select-1"
                                        identifier='select-default'
                                        :options="countries"
                                        :value="userBillingInfo.country.value"
                                        @change="(value)=>{userBillingInfo.country.value = value;}"
                                    />
                                </div>
                                <div class="select-arrow">
                                    <span class="ico">
                                        <img src="@/assets/img/icons/ico-arrowdown.svg">
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="mb-[12px] col-sm-6">
                            <label class="input-wraper input-wraper--label">
                                <input 
                                    type="text" 
                                    id="city" 
                                    class="input-text" 
                                    placeholder="City"
                                    v-model="userBillingInfo.city.value"
                                    :disabled="userBillingInfo.city.disabled"
                                    @input="disableBillingChangesBtn = false"
                                    @blur="onFieldBlur(userBillingInfo, ['city'])"
                                    :class="{ 'invalid': userBillingInfo.city.touched && !userBillingInfo.city.isValid }"
                                >
                                <div class="label-input">
                                    <span>City</span>
                                </div>
                            </label>
                        </div>
    
                        <div class="mb-[12px] col-sm-12">
                            <label class="input-wraper input-wraper--label">
                                <input 
                                    type="text" 
                                    id="address" 
                                    class="input-text" 
                                    placeholder="Address"
                                    v-model="userBillingInfo.address.value"
                                    :disabled="userBillingInfo.address.disabled"
                                    @input="disableBillingChangesBtn = false"
                                    @blur="onFieldBlur(userBillingInfo, ['address'])"
                                    :class="{ 'invalid': userBillingInfo.address.touched && !userBillingInfo.address.isValid }"
                                >
                                <div class="label-input">
                                    <span>Address</span>
                                </div>
                            </label>
                        </div>
                    </div>
    
                    <div data-id="business-account" class="business-fields" :class="{'business-active' : !!userBillingInfo.vat_registered_business.value}">
                        <div class="row">
                            <div class="mb-[12px] col-sm-6">
                                <label class="input-wraper input-wraper--label">
                                    <input 
                                        type="text" 
                                        id="business_name" 
                                        class="input-text" 
                                        placeholder="Business name"
                                        v-model="userBillingInfo.company_name.value"
                                        :disabled="userBillingInfo.company_name.disabled"
                                        @input="disableBillingChangesBtn = false"
                                        @blur="onFieldBlur(userBillingInfo, ['company_name'])"
                                        :class="{ 'invalid': userBillingInfo.company_name.touched && !userBillingInfo.company_name.isValid }"
                                    >
                                    <div class="label-input">
                                        <span>Business name</span>
                                    </div>
                                </label>
                            </div>
                            <div class="mb-[12px] col-sm-6">
                                <label class="input-wraper input-wraper--label">
                                    <input 
                                        type="text" 
                                        id="registration_number" 
                                        class="input-text" 
                                        placeholder="Registration number"
                                        v-model="userBillingInfo.vat_number.value"
                                        :disabled="userBillingInfo.vat_number.disabled"
                                        @input="disableBillingChangesBtn = false"
                                        @blur="onFieldBlur(userBillingInfo, ['vat_number'])"
                                        :class="{ 'invalid': userBillingInfo.vat_number.touched && !userBillingInfo.vat_number.isValid }"
                                    >
                                    <div class="label-input">
                                        <span>Registration number</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button @click="saveBilling" class="btn btn--primary px-[27px] py-[19px] rounded-[8px] font-[700] text-[14px] leading-[19px] mt-[21px]"
                        :class="{'disabled':disableBillingChangesBtn}">
                        <span>Save Changes</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { profileApi } from '@/api/profileApi';
import SelectBox from '../Base/SelectBox.vue';
import formValidation from '@/mixins/formValidation'
export default {
    data: () => ({
        userBillingInfo: false,
        disableBillingChangesBtn: true,
    }),
    components: {
        SelectBox
    },
    props: ['payload'],
    mixins: [formValidation],
    computed: {
        countries() {
            return Object.entries(this.payload.general.countryList).map((key) => ({value: key[0], label: key[1]}))
        },
    },
    watch: {
        payload: {
            deep: true,
            immediate: true,
            handler(val) {
                const data = val.user.address
                if (this.userBillingInfo) {
                    this.setValue(this.userBillingInfo, 'first_name', (data.first_name || ''));
                    this.setValue(this.userBillingInfo, 'last_name', (data.last_name || ''));
                    this.setValue(this.userBillingInfo, 'country', (data.country || ''));
                    this.setValue(this.userBillingInfo, 'city', (data.city || ''));
                    this.setValue(this.userBillingInfo, 'address', (data.address || ''));
                    this.setValue(this.userBillingInfo, 'vat_number', (data.vat_number || null), false);
                    this.setValue(this.userBillingInfo, 'company_name', (data.company_name || null), false);
                    this.setValue(this.userBillingInfo, 'vat_registered_business', (data.vat_registered_business || false), false);
                }

            }
        }
    },
    methods: {
        closeModal() {
            this.$bus.$emit("modal", {
                name: "",
                payload: {},
            });
        },
        async saveBilling() {

            const isValid = this.validate(this.userBillingInfo)

            if (!isValid) return

            console.log({...this.getValues(this.userBillingInfo)})

            this.disableField(this.userBillingInfo, ['first_name', 'last_name', 'country', 'city', 'address', 'vat_registered_business', 'company_name', 'vat_number'])

            this.disableBillingChangesBtn = true

            const response = await profileApi.address({...this.getValues(this.userBillingInfo)});

            this.enableField(this.userBillingInfo, ['first_name', 'last_name', 'country', 'city', 'address', 'vat_registered_business', 'company_name', 'vat_number'])

            this.disableBillingChangesBtn = false

            if (response.hasError) return;

            this.$set(this.payload.user, 'address', {
                ...response
            });
            this.closeModal();

        }
    },
    created() {
        this.userBillingInfo = this.createForm({
            first_name: {
                value: '',
                required: true
            },
            last_name: {
                value: '',
                required: true
            },
            country: {
                value: '',
                required: true
            },
            city: {
                value: '',
                required: true
            },
            address: {
                value: '',
                required: true
            },
            vat_number: {
                value: '',
                required: false,
            },
            company_name: {
                value: '',
                required: false,
            },
            vat_registered_business: {
                value: '',
                required: false
            },
        });

        const data = this.payload.user.address

        this.setValue(this.userBillingInfo, 'first_name', (data.first_name || ''), false);
        this.setValue(this.userBillingInfo, 'last_name', (data.last_name || ''), false);
        this.setValue(this.userBillingInfo, 'country', (data.country || ''), false);
        this.setValue(this.userBillingInfo, 'city', (data.city || ''), false);
        this.setValue(this.userBillingInfo, 'address', (data.address || ''), false);
        this.setValue(this.userBillingInfo, 'vat_number', (data.vat_number || null), false);
        this.setValue(this.userBillingInfo, 'company_name', (data.company_name || null), false);
        this.setValue(this.userBillingInfo, 'vat_registered_business', (data.vat_registered_business || false), false);
    }
};
</script>

<style scoped>

    .invalid {
        border: 1px solid red;
    }
    .switch-default {
        --button-width: 52px;
        --button-height: 32px;
        --toggle-diameter: 28px;
        --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
        --color-off: var(--text);
        --color-on: var(--primary-500);
        --button-radius: 24px;
        display: flex;
        cursor: pointer;
    }

    .switch-default input[type="checkbox"]:checked + .switch__slider {
        background-color: var(--color-on);
    }

    .switch-default input[type="checkbox"]:checked + .switch__slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
    }

    .business-fields {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    .business-active.business-fields {
        display: block;
        opacity: 1;
    }
</style>